<template>
  <div>
    <Header />
    <div class="container-slim mx-auto mb-5" style="padding-top: 90px;">
      <div class="py-5">
        <h2>
          Terms and Conditions - Little Red Tubs
        </h2>
        <hr class="mb-3" />
      </div>
      <p><strong>1. Definitions</strong></p>
      <p>
        <strong>1.1</strong> &ldquo;Owner&rdquo; shall mean Little Red Trucks
        Moving Co. Pty Ltd. Trading as &ldquo;Little Red Tubs&rdquo;, its
        successors and assigns or any person acting on behalf of and with the
        authority of Little Red Trucks Moving Co. Pty Ltd.
      </p>
      <p>
        <strong>1.2</strong> &ldquo;Hirer&rdquo; shall mean the Hirer or any
        person acting on behalf of and with the authority of the Hirer.
      </p>
      <p>
        <strong>1.3</strong> &ldquo;Guarantor&rdquo; means that person (or
        persons), or entity, who agrees to be liable for the debts of the Hirer
        on a principal debtor basis.
      </p>
      <p>
        <strong>1.4</strong> &ldquo;Equipment&rdquo; shall mean equipment
        supplied on hire by the Owner to the Hirer (and where the context so
        permits shall include any supply of services) and is as described on the
        invoices, quotation, authority to hire, or any other work authorisation
        forms as provided by the Owner to the Hirer.
      </p>
      <p>
        <strong>1.5</strong> &ldquo;Dry Hire&rdquo; shall mean that the
        Equipment is hired without an operator.
      </p>
      <p>
        <strong>1.6</strong> &ldquo;Price&rdquo; shall mean the cost of the hire
        of the Equipment as agreed between the Owner and the Hirer subject to
        clause 3 of this contract
      </p>
      <p><strong>2. Ownership</strong></p>
      <p>
        <strong>2.1</strong> All item(s) and equipment hired by the hirer is,
        and will remain, in the complete and sole ownership of the supplier All
        Packed. No item(s) or equipment can be transferred, offered for sale or
        hired, or sold in any circumstances from the hirer.
      </p>
      <p><strong>3. Acceptance</strong></p>
      <p>
        <strong>3.1</strong> Any instructions received by the Owner from the
        Hirer for the hire of Equipment and/or the Hirer&rsquo;s acceptance of
        Equipment supplied on hire by the Owner shall constitute acceptance of
        the terms and conditions contained herein.
      </p>
      <p>
        <strong>3.2</strong> Where more than one Hirer has entered into this
        agreement, the Hirers shall be jointly and severally liable for all
        payments of the Price.
      </p>
      <p>
        <strong>3.3</strong> Upon acceptance of these terms and conditions by
        the Hirer the terms and conditions are irrevocable and can only be
        amended with the written consent of the Owner.
      </p>
      <p>
        <strong>3.4</strong> The Hirer undertakes to give the Owner at least
        fourteen (14) days notice of any change in the Hirer&rsquo;s name,
        address and/or any other change in the Hirer&rsquo;s details.
      </p>
      <p>
        <strong>3.5</strong> Any instructions received by the Owner from the
        Hirer for the hire of Equipment and/or the Hirer&rsquo;s acceptance of
        Equipment supplied on hire by the Owner shall constitute acceptance of
        the terms and conditions contained herein.
      </p>
      <p><strong>4. Price And Payment</strong></p>
      <p>
        <strong>4.1</strong> At the Owner&rsquo;s sole discretion the Price
        shall be either;
      </p>
      <ol>
        <li>
          as indicated on invoices provided by the Owner to the Hirer in respect
          of Equipment supplied on hire; or
        </li>
        <li>
          the Owner&rsquo;s current Price at the date of the delivery of
          Equipment , according to the Owners current price list; or
        </li>
        <li>
          the Owner&rsquo;s quoted Price (Subject to clause 3.2) which shall be
          binding upon the owner provided that the Hirer shall accept in writing
          the Owner&rsquo;s quotation within thirty (30) days.
        </li>
      </ol>
      <p>
        <strong>4.2</strong> The Owner reserves the right to change the Price in
        the event of a variation to the Owner&rsquo;s quotation.
      </p>
      <p>
        <strong>4.3</strong> At the Owners sole discretion a deposit may be
        required.
      </p>
      <p>
        <strong>4.4</strong> Time for payment for the Equipment shall be of the
        essence and will be stated on the invoice or any other forms. If no time
        is stated then payment shall be at the time of delivery of equipment to
        The Hirer.
      </p>
      <p><strong>4.5</strong> At the Owner&rsquo;s sole discretion;</p>
      <ol>
        <li>
          payment shall be due on delivery of the Equipment, or
        </li>
        <li>
          payment for approved Hirers shall be made by instalments in accordance
          with the Owner&rsquo;s payment schedule.
        </li>
      </ol>
      <p>
        <strong>4.6</strong> The date upon which the Hirer advises of
        Termination shall in all cases be treated as a full day&rsquo;s hire
      </p>
      <p>
        <strong>4.7</strong> If the Equipment is lost, payment shall be due
        seven (7) days following the date of the invoice for the lost Equipment
      </p>
      <p>
        <strong>4.8</strong> Payment will be made by credit card (plus a
        surcharge of up to 1.2% of the Price)
      </p>
      <p>
        <strong>4.9</strong> GST and other taxes and duties that may be
        applicable shall be added to the Price except when they are expressly
        included in the Price
      </p>
      <p><strong>5. Hire Period</strong></p>
      <p>
        <strong>5.1</strong> Hire charges shall commence from the time the
        Equipment is delivered to the Hirer by the Owner and will continue until
        either;
      </p>
      <ol>
        <li>
          the return of the Equipment to the Owner&rsquo;s premises and/or until
          the expiry of the minimum Hire Period, whichever last occurs; or
        </li>
        <li>
          Compensation has been made for the loss or non-return of the
          Equipment.
        </li>
      </ol>
      <p>
        <strong>5.2</strong> If the Owner agrees with the Hirer to deliver
        and/or collect the Equipment, hire charges shall commence from the time
        the Equipment leaves the Owner&rsquo;s premises and continue until the
        Hirer notifies the Owner that the Equipment is available for collection.
      </p>
      <p>
        <strong>5.3</strong> No allowance whatsoever can be made for time during
        which the Equipment is not in use for any reason, unless the Owner
        confirms prior special arrangements in writing. In the event of
        Equipment breakdown provided the Hirer notifies the Owner immediately,
        hiring charges will not be payable during the time the Equipment is not
        working, unless the condition is due to negligence or misuse on the part
        of or attributable to the Hirer.
      </p>
      <p><strong>6. Delivery of Equipment</strong></p>
      <p>
        <strong>6.1</strong> At the Owners sole discretion delivery of the
        equipment shall take place when:
      </p>
      <ol>
        <li>
          The hirer takes possession of the Equipment at the Owner&rsquo;s
          address; or
        </li>
        <li>
          The hirer takes possession of the Equipment at the Hirer&rsquo;s
          address (in the event that the Equipment are delivered by the Owner or
          the Owner&rsquo;s nominated carrier); or
        </li>
        <li>
          The Hirer&rsquo;s nominated carrier takes possession of the Equipment
          in which event the carrier shall be deemed to be the Hirer&rsquo;s
          agent
        </li>
      </ol>
      <p>
        <strong>6.2</strong> At the Owner&rsquo;s sole discretion the costs of
        delivery are:
      </p>
      <ol>
        <li>Included in the Price; or</li>
        <li>In addition to the Price; or</li>
        <li>For the Hirer&rsquo;s account</li>
      </ol>
      <p>
        <strong>6.3</strong> The Hirer shall make all arrangements necessary to
        take delivery of the Equipment whenever it is tendered for delivery. In
        the event that the Hirer is unable to take delivery of the Equipment as
        arranged then the Owner shall be entitled to charge a reasonable fee for
        redelivery.
      </p>
      <p>
        <strong>6.4</strong> The Owner may deliver the Equipment by separate
        instalments. Each separate instalment shall be invoiced and paid for in
        accordance with the provisions in these terms and conditions.
      </p>
      <p>
        <strong>6.5</strong> Delivery of the Equipment to a third party
        nominated by the Hirer is deemed to be delivery to the Hirer for the
        purpose of this agreement
      </p>
      <p>
        <strong>6.6</strong> The Hirer shall be responsible for free access by
        the Owner to the site on which the Equipment is located. If there are
        any delays due to free access not being available then the Hirer shall
        be responsible and shall reimburse the Owner for all lost hire fees
        associated with the Equipment being unavailable. The Hirer shall also be
        responsible for all other expenses and costs incurred by the Owner due
        to delays in access to the Equipment. The off-hire receipt will be
        issued when the Equipment is picked up by the owner or returned to the
        Owner&rsquo;s premises.
      </p>
      <p>
        <strong>6.7</strong> The failure of the Owner to deliver shall not
        entitle either party to treat this contract as repudiated.
      </p>
      <p>
        <strong>6.8</strong> The Owner shall not be liable for any loss or
        damage whatever due to failure by the Owner to deliver the Equipment (or
        any of them) promptly or not at all.
      </p>
      <p><strong>7. Risk</strong></p>
      <p>
        <strong>7.1</strong> The Owner retains property of the Equipment
        nonetheless; all risk for the Equipment passes to the Hirer on Delivery.
      </p>
      <p>
        <strong>7.2</strong> The Hirer accepts full responsibility for the
        safekeeping of the Equipment and indemnifies the Owner for all loss
        theft or damage to the Equipment howsoever caused and without limiting
        the generality of the foregoing whether or not such loss theft or damage
        is attributable to any negligence failure or omission of the Hirer.
      </p>
      <p>
        <strong>7.3</strong> The Hirer will insure, or self insure, the
        Owner&rsquo;s interest in the Equipment against physical loss or damage
        including, but not limited to, the perils of accident, fire, theft and
        burglary and all other usual risks and will effect adequate Public
        Liability Insurance covering any loss, damage or injury to property
        arising out of the Equipment. Further the Hirer will not use the
        Equipment nor permit it to be used in such a manner as would permit an
        insurer to decline any claim.
      </p>
      <p>
        <strong>7.4</strong> The Hirer accepts full responsibility for and shall
        keep the Owner indemnified against all liability in respect of all
        actions, proceedings, claims, damages, costs and expenses in respect of
        any injury to persons or damage to property arising out of the use of
        the Equipment during the hire period however arising and whether or not
        arising from any negligence, failure or omission of the Hirer or any
        other persons.
      </p>
      <p><strong>8. Title</strong></p>
      <p>
        <strong>8.1</strong> The Equipment is and will at all time remain the
        absolute property of the Owner
      </p>
      <p>
        8.2 If the Hirer fails to return the Equipment to the Owner then the
        Owner or the Owner&rsquo;s agent may enter upon and into land and
        premises owned, occupied or used by the Hirer, or any premises where the
        Equipment is situated as the invitee of the Hirer and take possession of
        the Equipment, without being responsible for any damage thereby caused.
      </p>
      <p>
        <strong>8.3</strong> The Hirer is not authorised to pledge the
        Owner&rsquo;s credit for repairs to the Equipment or to create a lien
        over the Equipment in respect of any repairs.
      </p>
      <p><strong>9. Defects</strong></p>
      <p>
        <strong>9.1</strong> The Hirer shall inspect the Equipment on delivery
        and shall immediately notify the Owner of any alleged defect, shortage
        in quantity, damage or failure to comply with the description or quote.
        The Hirer shall afford the Owner an opportunity to inspect the Equipment
        within a reasonable time following delivery if the Hirer believes the
        Equipment is defective in any way. If the Hirer shall fail to comply
        with these provisions the Equipment shall be presumed to be free from
        any defect or damage. For defective Equipment, which the Owner has
        agreed in writing that the Hirer is entitled to reject, the
        Owner&rsquo;s liability is limited to replacing the Equipment
      </p>
      <p><strong>10. Warranty</strong></p>
      <p>
        <strong>10.1</strong> No Warranty is provided by the Owner in respect of
        the condition of the Equipment or its fitness for any particular
        purpose. The Hirer shall indemnify and hold harmless the Owner in
        respect of all claims arising out of use of the Equipment.
      </p>
      <p><strong>11. Hirer&rsquo;s Disclaimer</strong></p>
      <p>
        <strong>11.1</strong> The Hirer hereby disclaims any right to rescind,
        or cancel the contract or to sue for damages or to claim restitution
        arising out of any misrepresentation made to the Hirer by the Owner and
        the Hirer acknowledges that the Equipment is hired relying solely upon
        the Hirer&rsquo;s skill and judgement.
      </p>
      <p><strong>12. Hirer&rsquo;s Responsibilities</strong></p>
      <p><strong>12.1</strong> The Hirer shall:</p>
      <ol>
        <li>
          satisfy itself at Commencement that the Equipment is suitable for its
          purposes; and
        </li>
        <li>
          operate the Equipment safely, strictly in accordance with the law,
          only for its intended use (including, but not limited, not using the
          Equipment for the transporting of flammables, dangerous or hazardous
          goods, and chemicals), and in accordance with any manufacturer&rsquo;s
          instruction whether supplied by the Owner or posted on the Equipment;
          and
        </li>
        <li>
          ensure that all persons operating or erecting the Equipment are
          suitably instructed in its safe and proper use and where necessary
          hold a current Certificate of Competency and/or are fully licensed;
          and
        </li>
        <li>
          comply with all occupational health and safety laws relating to the
          Equipment and its operation; and on termination of the hire, the Hirer
          shall deliver the Equipment complete with all parts and accessories
          clean and in good order as delivered, fair wear and tear accepted, to
          the Owner; and
        </li>
        <li>
          on termination of the hire, The Hirer shall deliver the Equipment
          complete with all parts and accessories clean and in good order as
          delivered, fair wear and tear accepted, to the Owner; and
        </li>
        <li>
          keep the Equipment in their own possession and control and shall not
          assign the benefit of the hire contract nor be entitled to lien over
          the Equipment; and
        </li>
        <li>
          not alter or make any additions to the Equipment including but without
          limitation altering, make any additions to, defacing or erasing any
          identifying mark, plate or number on or in the Equipment or in any
          other manner interfere with the Equipment; and
        </li>
        <li>
          employ the Equipment solely in its own work and shall not permit the
          Equipment of any part thereof to be used by any other party for any
          other work; and
        </li>
        <li>
          not fix any of the Equipment in such a manner as to make it legally a
          fixture forming part of any freehold; and
        </li>
        <li>
          return the Equipment empty, clean, dry and free of rubbish with labels
          removed
        </li>
      </ol>
      <p>
        <strong>12.2</strong> Immediately on request by the Owner the Hirer will
        pay:
      </p>
      <ol>
        <li>
          The new list price of any Equipment that is for whatever reason
          destroyed, written off or not returned to Owner;
        </li>
        <li>all costs incurred in cleaning the Equipment;</li>
        <li>
          all costs of repairing any damage caused by the ordinary use of the
          Equipment up to an amount equal to 10% of the new list price of the
          Equipment;
        </li>
        <li>
          The cost of repairing any damage to the Equipment caused by the
          negligence of the Hirer or the Hirer&rsquo;s agent;
        </li>
        <li>
          The cost of repairing any damage to the Equipment caused by vandalism,
          or (in the Owner&rsquo;s reasonable opinion) in any way whatsoever
          other than by the ordinary use of the Equipment by the Hirer
        </li>
        <li>
          The cost of consumables provided by Owner and used by the Hirer.
        </li>
      </ol>
      <p><strong>13. Cancellation</strong></p>
      <p>
        <strong>13.1</strong> The Owner may cancel these terms and conditions or
        cancel delivery of Equipment at any time before the Equipment is
        delivered by giving written notice. On giving such notice the Owner
        shall repay to the Hirer any sums paid in respect of the Price. The
        Owner shall not be liable for any loss or damage whatever arising from
        such cancellation
      </p>
      <p>
        <strong>13.2</strong> In the event that the Hirer cancels delivery of
        the Equipment the Hirer shall be liable for any loss incurred by the
        Owner (including, but not limited to, any loss of profits) up to the
        time of cancellation.
      </p>
      <p>
        <strong
          >14. The Commonwealth Trade Practices Act 1974 and Fair Trading
          Acts</strong
        >
      </p>
      <p>
        <strong>14.1</strong> Nothing in this agreement is intended to have the
        effect of contracting out of any applicable provisions of the
        Commonwealth Trade Practices Act 1974 or the Fair Trading Acts in each
        of the States and Territories of Australia, except to the extent
        permitted by those Acts where applicable.
      </p>
      <p>
        <strong>15. Default &amp; Consequences of Default</strong>
      </p>
      <p>
        <strong>15.1</strong> Interest on overdue invoices shall accrue from the
        date when payment becomes due daily until the date of payment at a rate
        of 2.5% per calendar month and such interest shall compound monthly at
        such a rate after as well as before any judgement
      </p>
      <p>
        <strong>15.2</strong> If the Hirer defaults in payment of any invoice
        when due, the Hirer shall indemnify the Owner from and against all costs
        and disbursements incurred by the Owner in pursuing the debt including
        legal costs on a solicitor and own Hirer basis and the Owner&rsquo;s
        collection agency costs.
      </p>
      <p>
        <strong>15.3</strong> Without prejudice to any other remedies the Owner
        may have, if at any time the Hirer is in breach of any obligation
        (including those relating to payment), the Owner may suspend or
        terminate the supply of Equipment to the Hirer and any of its other
        obligations under the terms and conditions. The Owner will not be liable
        to the Hirer for any loss or damage the Hirer suffers because the Owner
        exercised its rights under this clause.
      </p>
      <p>
        <strong>15.4</strong> If any account remains overdue after thirty (30)
        days then an amount of the greater of $20.00 or 10.00% of the amount
        overdue (up to a maximum of $200) shall be levied for administration
        fees which sum shall become immediately due and payable
      </p>
      <p>
        <strong>15.5</strong> Without prejudice to the Owner&rsquo;s other
        remedies at law the Owner shall be entitled to cancel all or any part of
        any order of the Hirer which remains unperformed in addition to and
        without prejudice to any other remedies and all amounts owing to the
        Owner shall, whether or not due for payment, become immediately payable
        in the event that:
      </p>
      <ol>
        <li>
          any money payable to the Owner becomes overdue, or in the
          Owner&rsquo;s opinion the Hirer will be unable to meet its payments as
          they fall due; or
        </li>
        <li>
          the Hirer becomes insolvent, convenes a meeting with its creditors or
          proposes or enters into an arrangement with creditors, or makes an
          assignment for the benefit of its creditors; or
        </li>
        <li>
          a receiver, manager, liquidator (provisional or otherwise) or similar
          person is appointed in respect of the Hirer or any asset of the Hirer
        </li>
      </ol>
      <p><strong>16. Security And Charge</strong></p>
      <p>
        <strong>16.1</strong> Despite anything to the contrary contained herein
        or any other rights which the Owner may have howsoever:
      </p>
      <ol>
        <li>
          where the Hirer and/or the Guarantor (if any) is the owner of land,
          realty or any other asset capable of being charged, both the Hirer
          and/or the Guarantor agree to mortgage and/or charge all of their
          joint and/or several interest in the said land, realty or any other
          asset to the Owner or the Owner&rsquo;s nominee to secure all amounts
          and other monetary obligations payable under the terms and conditions.
          The Hirer and/or the Guarantor acknowledge and agree that the Owner
          (or the Owner&rsquo;s nominee) shall be entitled to lodge where
          appropriate a caveat, which caveat shall be released once all payments
          and other monetary obligations payable hereunder have been met
        </li>
        <li>
          should the Owner elect to proceed in any manner in accordance with
          this clause and/or its subclauses, the Hirer and/or Guarantor shall
          indemnify the Owner from and against all the Owner&rsquo;s costs and
          disbursements including legal costs on a solicitor and own Hirer
          basis.
        </li>
        <li>
          the Hirer and/or the Guarantor (if any) agree to irrevocably nominate
          constitute and appoint the Owner or the Owner&rsquo;s nominee as the
          Hirer&rsquo;s and/or Guarantor&rsquo;s true and lawful attorney to
          perform all necessary acts to give effect to the provisions of this
          clause 15.1.
        </li>
      </ol>
      <p><strong>17. Privacy Act 1988</strong></p>
      <p>
        <strong>17.1</strong> The Hirer and/or the Guarantor/s agree for the
        Owner to obtain from a credit reporting agency a credit report
        containing personal credit information about the Hirer and Guarantor/s
        in relation to credit provided by the Owner.
      </p>
      <p>
        <strong>17.2</strong> The Hirer and/or the Guarantor/s agree that the
        Owner may exchange information about the Hirer and the Guarantor/s with
        those credit providers either named as trade referees by the Hirer or
        named in a consumer credit report issued by a credit reporting agency
        for the following purposes:
      </p>
      <ol>
        <li>to assess an application by Hirer; and/or</li>
        <li>
          to notify other credit providers of a default by the Hirer; and/or
        </li>
        <li>
          to exchange information with other credit providers as to the status
          of this credit account, where the Hirer is in default with other
          credit providers; and/or
        </li>
        <li>
          to assess the credit worthiness of Hirer and/or Guarantor/s.
        </li>
      </ol>
      <p>
        <strong>17.3</strong> The Hirer consents to the Owner being given a
        consumer credit report to collect overdue payment on commercial credit
        (Section 18K(1)(h) Privacy Act 1988).
      </p>
      <p>
        <strong>17.4</strong> The Hirer agrees that personal credit information
        provided may be used and retained by the Owner for the following
        purposes and for other purposes as shall be agreed between the Hirer and
        Owner or required by law from time to time:
      </p>
      <ol>
        <li>provision of Services; and/or</li>
        <li>
          marketing of Services by the Owner, its Owners or distributors in
          relation to the Services; and/or
        </li>
        <li>
          analysing, verifying and/or checking the Hirer&rsquo;s credit, payment
          and/or status in relation to provision of Services; and/or
        </li>
        <li>
          processing of any payment instructions, direct debit facilities and/or
          credit facilities requested by Hirer; and/or
        </li>
        <li>
          enabling the daily operation of Hirer&rsquo;s account and/or the
          collection of amounts outstanding in the Hirer&rsquo;s account in
          relation to the Services.
        </li>
      </ol>
      <p>
        <strong>17.5</strong> The Owner may give information about the Hirer to
        a credit reporting agency for the following purposes:
      </p>
      <ol>
        <li>
          to obtain a consumer credit report about the Hirer; and/or
        </li>
        <li>
          allow the credit reporting agency to create or maintain a credit
          information file containing information about the Hirer.
        </li>
      </ol>
      <p>
        <strong>18. Unpaid Owner&rsquo;s Rights to Dispose of Goods</strong>
      </p>
      <p>
        The Owner shall have a lien on the Goods and any documents relating to
        the Goods and/or any other Goods or cargo of the Hirer in the possession
        or control of the Owner and any documents relating to those other Goods
        or cargo for all sums payable by the Hirer to the Owner for that purpose
        and shall have the right to sell such Goods or cargo by public auction
        or private treaty given twenty eight (28) days written notice to the
        Hirer. The Owner shall be entitled to retain the sums due to it, in
        addition to the charges incurred in detention and sale of such Goods or
        cargo, from the proceeds of sale and shall render any surplus to the
        entitled person.
      </p>
      <p><strong>19. General</strong></p>
      <p>
        <strong>19.1</strong> If any provision of these terms and conditions
        shall be invalid, void, illegal or unenforceable the validity,
        existence, legality and enforceability of the remaining provisions shall
        not be affected, prejudiced or impaired.
      </p>
      <p>
        <strong>19.2</strong> These terms and conditions and any contract to
        which they apply shall be governed by the laws of Victoria and are
        subject to the jurisdiction of the courts of Victoria.
      </p>
      <p>
        <strong>19.3</strong> The Owner shall be under no liability whatever to
        the Hirer for any indirect loss and/or expense (including loss of
        profit) suffered by the Hirer arising out of a breach by the Owner of
        these terms and conditions.
      </p>
      <p>
        <strong>19.4</strong> In the event of any breach of this contract by the
        Owner the remedies of the Hirer shall be limited to damages. Under no
        circumstances shall the liability of the Owner exceed the Price of the
        Services.
      </p>
      <p>
        <strong>19.5</strong> The Hirer shall not be entitled to set off against
        or deduct from the Price any sums owed or claimed to be owed to the
        Hirer by the Owner
      </p>
      <p>
        <strong>19.6</strong> The Owner may license or sub-contract all or any
        part of its rights and obligations without the Hirer&rsquo;s consent.
      </p>
      <p>
        <strong>19.7</strong> The Owner reserves the right to review these terms
        and conditions at any time. If, following any such review, there is to
        be any change to these terms and conditions, then that change will take
        effect from the date on which the Owner notifies the Hirer of such
        change.
      </p>
      <p>
        <strong>19.8</strong> Neither party shall be liable for any default due
        to any act of God, war, terrorism, strike, lock out, industrial action,
        fire, flood, drought, storm or other event beyond the reasonable control
        of either party
      </p>
      <p>
        <strong>19.9</strong> The failure by the Owner to enforce any provision
        of these terms and conditions shall not be treated as a waiver of that
        provision, nor shall it affect the Owners right to subsequently enforce
        that provision.
      </p>
    </div>
    <div class="footer text-center p-5 fixed-bottom">
      <b>Need a hand? Call us on <a href="tel:93806444">9380 6444</a></b>
    </div>
  </div>
</template>

<script lang="js">
import Header from '@/components/Header'

export default {
  components: {
    Header
  }
}
</script>
