<template>
  <div>
    <Header />
    <div class="container-slim mx-auto mb-5" style="padding-top: 90px;">
      <div class="py-5">
        <h2>Thank you for your booking!</h2>
        <hr class="mb-5" />
        <h3>
          An email with your booking details as been sent to the email address
          you provided.
        </h3>
      </div>
    </div>
    <div class="footer text-center p-5 fixed-bottom">
      <b>Need a hand? Call us on <a href="tel:93806444">9380 6444</a></b>
    </div>
  </div>
</template>

<script lang="js">
import Header from '@/components/Header'

export default {
  components: {
    Header
  }
}
</script>
